.color-circle {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin: 8px;
    cursor: pointer;
}

.load-screen {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s;
}

.cover {
    position: fixed;
    opacity: 0;
    top: 0;
    left: 0;
    background: #000000;
    z-index: -1;
}

.show {
    z-index: 100;
    opacity: 0.75;
}

.spinner {
    animation: spin 1s linear infinite;
    border: 4px solid black;
    border-radius: 50%;
    border-top-color: #fff;
    height: 40px;
    width: 40px;
}

.sections {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.task {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    margin-left: 16px;
}

.app-link {
    color: black;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;
    font-weight: bold;
}

.app-link div {
    margin-left: 10px;
}

.create {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.page-title {
    margin-bottom: 24px;
    margin-right: auto;
    margin-left: 16px;
}

.add-media {
    display: flex;
    alignItems: center;
    cursor: pointer;
}

.circles > .color-circle:first-child {
    margin-left: 0;
}

.circles {
    flex-wrap: wrap;
}

.project-pill {
    min-width: calc(33.33% - 32px);
    min-height: 50px;
    padding: 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 16px;

    @media (max-width: 480px) {
        min-width: 100%;
        margin: 16px 0;
    }
}

.get-all {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
