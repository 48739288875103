.main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.login-container {
    width: 300px;
    display: flex;
    flex-direction: column;
}

.login-container button {
    width: 200px;
    align-self: center;
}

.login-error {
    color: red;
}
