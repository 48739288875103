body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.my-modal {
    position: fixed;
    background: white;
    border: 1px solid grey;
    border-radius: 4px;
    padding: 16px;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    width: 600px;
    max-width: calc(100% - 32px);
}

.my-modal button {
    margin-left: auto;
}

.close-modal {
    margin-right: auto;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

svg {
    cursor: pointer;
}

.margin-bottom {
    margin-bottom: 16px !important;
}
